.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .growing-wine {
    animation: growAndSpin 3s infinite alternate-reverse;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.page-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ececec;
}

.page-section .image {
  animation-name: fadeIn;
  animation-duration: 3s;
  animation-delay: 1s;
}

.iframe-container {
}

.iframe-container .iframe {
  width: 720px;
  height: 360px;
  max-width: 100%;
}

@keyframes growAndSpin {
  from {
    transform: rotate(0deg) scale(0.8);
  }
  to {
    transform: rotate(90deg) scale(1.75);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
